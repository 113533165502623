import Content from "../common/Content";
import Footer from "../common/Footer";
import Header from "../common/Header";
import DataTable from "react-data-table-component";
import { API } from "../../helper/Api";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RotatingLines } from "react-loader-spinner";

export default function CatalogList() {
  const [photos, setPhotos] = useState([]);
  const [items, setItems] = useState([]);
  const [mainData, setmainData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    catalogList();
  }, [page]);

  const catalogList = () => {
    setPending(true);

    API.getData({ page })
      .then((datas) => {
        const items = datas.ApiDocument.Response.Styles.flatMap(
          (style) => style
        );
        const totalRecords = datas.ApiDocument.TotalRecords;
        //const itemIterate = datas.ApiDocument.Response.Styles.flatMap(mainStyle => mainStyle.StoreDescription);
        setItems(items);
        setTotalRecords(totalRecords);
        setPending(false);
        //  setmainData(mainData);
        // let array = [];
        // const data = items.map((i, j) => {
        //   itemIterate.map((k, l) => {
        //     if(!array.includes(i.ExternalId)){
        //       i.StoreDescription = k; // Assign the value of l to k.storeDesc
        //       array.push(i); // Push i into array
        //     }
        //   });
        // });
        // setItems(array);

        //  // Example: Display item details
        //  items.forEach(item => {
        //   console.log(`Item PLU: ${item.PLU}`);
        //   console.log(`Item UPC: ${item.UPC}`);
        //   console.log(`Item Base Price: ${item.BasePrice}`);
        // });
      })
      .catch((err) => console.log(err));
  };
const catalogSearch = (e) => {
          const searchKey = e.target.value;
          if(searchKey){
          API.getSearchData({searchKey})
          .then((datas) => {
            const items = datas.ApiDocument.Response.Styles.flatMap(
              (style) => style
            );
            const totalRecords = datas.ApiDocument.TotalRecords;
            setItems(items);
            setTotalRecords(totalRecords);
            setPending(false);
          })
          .catch((err) => console.log(err));
        }else{
          catalogList();

        }

}
  const nextButton = document.getElementById("pagination-next-page");

  const handleRowClicked = (row) => {
    // console.log("row", row);
    // console.log("row_id",totalRows);
    setPage(row);
    
  };
const dataSearch =() =>{
  alert("Hi");
}
  const columns = [
    // { name: 'ID', selector: row => row.StyleNo, sortable: true },
    {
      name: "Product Description",
      selector: (row) => row.StoreDescription,
      sortable: true,
    },
    { name: "Product Code", selector: (row) => row.StyleNo, sortable: true },
    { name: "PLU", selector: (row) => row.Items[0].PLU, sortable: true },
    { name: "CLU", selector: (row) => row.Items[0].CLU, sortable: true },
    { name: "UPC", selector: (row) => row.Items[0].UPC, sortable: true },
    {
      name: "Price",
      selector: (row) => row.Items[0].BasePrice,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <a href={`catalog-price-update?id=${row.StyleNo}`} value={row.StyleNo}>
          Edit
        </a>
      ),
      sortable: true,
    },
    // Add more columns as needed
  ];

  // const RenderData = () => {
    return (
      <>
            <Header />
            <div class="container-fluid page-body-wrapper">
          <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
              <li class="nav-item nav-category"></li>
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-cube menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Catalog List</span>
                  {/* <i class="menu-arrow"></i> */}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="add-new-catalog">
                  <span class="icon-bg"><i class="mdi mdi-contacts menu-icon"></i></span>
                  <span class="menu-title">Add New Catalog</span>
                </a>
              </li> 
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-contacts menu-icon"></i></span>
                  <span class="menu-title">WEB Orders</span>
                </a>
              </li>                                          
              {/* <li class="nav-item">
                <a class="nav-link" href="pages/icons/mdi.html">
                  <span class="icon-bg"><i class="mdi mdi-contacts menu-icon"></i></span>
                  <span class="menu-title">Orders</span>
                </a>
              </li> */}
              
              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li>
            </ul>
          </nav>


            <div class="main-panel">
        <div class="content-wrapper">
          {/* {pending ? (
            <div className="loader">
              <RotatingLines
                visible={true}
                height="96"
                width="96"
                strokeColor="grey"
                strokeWidth="2"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />{" "}
            </div>
          ) : ( */}
            <div>
            {/* <form class="example" action="/action_page.php"> */}
            <div id="catalogSearch">
               <input type="text" placeholder="Search CLU.." name="search2" onChange={catalogSearch}/>
            </div>
              {/* <button type="submit" onClick={dataSearch}><i class="fa fa-search"></i></button> */}
            {/* </form>  */}

             <DataTable
                title="Catalog List"
                columns={columns}
                data={items}
                pagination
                paginationServer={true}
                // onChangeRowsPerPage={handleRowClicked}
                onChangePage={handleRowClicked}
                paginationTotalRows={totalRecords}
                paginationPerPage={10}
                progressPending={pending}

              />
            </div>
          {/* )} */}
        </div>
      </div>


      </div>  

      <Footer />
      </>
      
    );
  // };

  // return (
  //   <>
  //     {/* {photos.map((data) => (
  //           <h1>{data.id}</h1>
  //       ))} */}
  //     <Header />
  //     <Content data={RenderData} />
  //     <Footer />
  //   </>
  // );
}
