import Content from "../common/Content"
import Footer from "../common/Footer"
import Header from "../common/Header"
import { API } from "../../helper/Api";
import { useSearchParams,useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner'



export default function CatalogPriceUpdate(){
    
    // let id  = useSearchParams(); 
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id')
    const [postValue, setPosts] = useState([]);
    const [stores, setStores] = useState([]);
    const [title , setTitle] = useState("");
    const [itemnumber , setItemnumber] = useState("");
    const [price , setPrice] = useState("");
    const [plu , setPLU] = useState("");
    const [newPrice , setnewPrice] = useState("");
    const navigate = useNavigate();
    const [pending, setPending] = useState(true);
    const [store, setStore] = useState("");
    


    // const [updateTitle , setupdateTitle] = useState([]);

    useEffect(() => {
        API.getSpecificData({id})
        .then(datas => {
          const items = datas.ApiDocument.Response.Styles.flatMap(style => style);

          setPosts(items);
          setPending(false);
        })
        .catch((err) => console.log(err))
    }, [id]);

    useEffect(() => {
      if(postValue[0] != undefined){
        setStores(postValue[0].Items[0].Prices);
        setTitle(postValue[0].StoreDescription);
        setItemnumber(postValue[0].Items[0].CLU);
        setPLU(postValue[0].Items[0].PLU );
        setPrice(postValue[0].Items[0].BasePrice);
      }
    }, [postValue[0]]);   


    const updateCallBack = (result) =>{
        if(result.Id) {
            toast.success('Price Updated', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                });
            setTimeout(() => {
              window.location.reload();

                // navigate("/catalog-list");
              }, 3000);            
            //return navigate("/catalog-list");
        } 
    }
    const updatePrice = (e) => {
       e.preventDefault();
      //  setStore(e.target.form.store.value);
      //  const oldprice = e.target.form.oldprice.value;
      //  const itemnumber = e.target.form.itemnumber.value;
      //  const newprice = e.target.form.newprice.value;
       API.updateCatalogPrice({ store,itemnumber,newPrice })
         .then(result => updateCallBack(result))
         .catch(error => console.log('error', error));
      
    }
    const   storeChange = (e) => {
      setStore(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text);
      const selectStore = e.target.options[e.target.selectedIndex].text;
      setPrice(e.target.value);
      const selectedStore = stores.find((member) => member.PriceLevel === selectStore);
      if (selectedStore) {
        setPrice(selectedStore.Price);
      }
      // this.setState({
      //     selectedOption: target.value,
      // });
    }
    // const RenderData = () => {
        return (
            <>
<ToastContainer
position="top-center"
autoClose={500}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>

<Header />
            <div class="container-fluid page-body-wrapper">
          <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
              <li class="nav-item nav-category"></li>
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-cube menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Catalog List</span>
                  {/* <i class="menu-arrow"></i> */}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="add-new-catalog">
                  <span class="icon-bg"><i class="mdi mdi-contacts menu-icon"></i></span>
                  <span class="menu-title">Add New Catalog</span>
                </a>
              </li> 
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-contacts menu-icon"></i></span>
                  <span class="menu-title">WEB Orders</span>
                </a>
              </li> 
              
              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li>
            </ul>
          </nav>

       <div class="main-panel">
          <div class="content-wrapper">
            <div class="page-header">
              <h3 class="page-title">Catalog Price Update </h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/catalog-list" class="btn btn-primary">Back</a></li>
                  {/* <li class="breadcrumb-item active" aria-current="page">Catalog Price Update</li> */}
                </ol>
              </nav>
            </div>
              <div class="col-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                   { pending ? <div className="loader">   
   <RotatingLines
    visible={true}
    height="96"
    width="96"
    strokeColor="grey"
    strokeWidth="2"
    animationDuration="0.75"
    ariaLabel="rotating-lines-loading"
    wrapperStyle={{}}
    wrapperClass=""
    /> </div>  : 
    <div className="productDetails">
    <h4 class="card-title">Product Name : {title}</h4>
    <p class="card-description">PLU : {plu} </p>
    <form class="forms-sample">
        <div class="row">
            <div class="col-6 grid-margin">
            <div class="form-group">
        <label for="exampleSelectGender">Store</label>
        <select class="form-control" id="store" onChange={storeChange} >
        <option value="">Select Store</option>
        {/* {stores.map(( value) => 
        // <option selected={value['PriceLevel'] === 'BASEPRICE' ? "selected" : ''} value={value['Price']}>{value['PriceLevel']}</option>)
        <option value={value['Price']}>{value['PriceLevel']}</option>)
        } */}
          <option value="">BASEPRICE</option>
          <option value="">Bend</option>
          <option value="">440</option>
          <option value="">443</option>
          <option value="">404</option>
        </select>

      </div>
      <div class="form-group">
        <label for="exampleInputName1">Old Price</label>
        <input type="text" class="form-control" id="oldprice" placeholder="0.00"  onChange={(e)=>setPrice(e.target.value)} value={price}/>
      </div> 
            </div>
            <div class="col-6 grid-margin">
            <div class="form-group">
        <label for="exampleInputName1">Item Number</label>
        <input type="text" class="form-control" id="itemnumber" placeholder="Name" onChange={(e)=>setItemnumber(e.target.value)} value={itemnumber}/>
      </div>
      <div class="form-group">
        <label for="exampleInputCity1">Enter New Price</label>
        <input type="text" class="form-control" id="newprice" placeholder="0.00" onChange={(e)=>setnewPrice(e.target.value)} value={newPrice}/>
      </div>
            </div>
        </div>

      <button type="submit" class="btn btn-primary mr-2" onClick={updatePrice} >Update</button>
      <button class="btn btn-light">Cancel</button>
    </form>
    </div>
    }
 
                  </div>
                </div>
              </div>
            </div>
          </div> 
          
          </div>  

<Footer />
                     </>
        )
    }
    // return (
    //     <>
    //             <Header />
    //             <Content  data={RenderData}/>
    //             <Footer />
    //     </>
    // )
//}