import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Route, BrowserRouter, Routes} from 'react-router-dom';
import CatalogList from './component/page/CatalogList.js'
import CatalogPriceUpdate from './component/page/CatalogPriceUpdate.js';
import SalesOrders from './component/page/SalesOrders.js';
import AddNewCatalog from './component/page/AddNewCatalog.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
      <Routes>
        <Route path="/"  exact element={<App />}/>
        <Route path="/catalog-list"  exact element={<CatalogList />}/>
        <Route path="/add-new-catalog"  exact element={<AddNewCatalog />}/>
        <Route path="/catalog-price-update"  exact element={<CatalogPriceUpdate />}/>
        <Route path="/web-orders"  exact element={<SalesOrders />}/>

      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
