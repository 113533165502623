import Content from "../common/Content"
import Footer from "../common/Footer"
import Header from "../common/Header"
import { API } from "../../helper/Api";
import { useSearchParams,useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RotatingLines } from 'react-loader-spinner'




export default function AddNewCatalog(){

const [dept, setDept] = useState(0);
const [deptCode, setDeptCode] = useState(0);
const [primaryVendor, setPrimaryVendor] = useState("");
const [description, setDescription] = useState("");
const [clu, setClu] = useState("");
// const [upc, setUPC] = useState("");
const [taxGroupCode, setTaxGroupCode] = useState(0);
const [basePrice, setBasePrice] = useState(0);
const navigate = useNavigate();



const newCatalog =(e) =>{
    e.preventDefault();
    API.addCatalog({dept,deptCode,primaryVendor,description,clu,taxGroupCode,basePrice});
    toast.success('Added Catalog', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
      });
  setTimeout(() => {
      navigate("/add-new-catalog");
    }, 3000); 

    // .catch(error => console.log('error', error));
}
const addCallBack = (result) => {
  if(result) {
    toast.success('Added Catalog', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        });
    setTimeout(() => {
        navigate("/add-new-catalog");
      }, 3000);            
}}

const selectDept=(e)=>{
    setDept(e.target.value);
}
const selectDeptClassCode=(e)=>{
    setDeptCode(e.target.value);
}
    return <>
    <ToastContainer
position="top-center"
autoClose={500}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="colored"
/>
    
    <Header />            
    <div class="container-fluid page-body-wrapper">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
            <ul class="nav">
              <li class="nav-item nav-category"></li>
              <li class="nav-item">
                <a class="nav-link" href="/">
                  <span class="icon-bg"><i class="mdi mdi-cube menu-icon"></i></span>
                  <span class="menu-title">Dashboard</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="catalog-list" aria-expanded="false" aria-controls="ui-basic">
                  <span class="icon-bg"><i class="mdi mdi-crosshairs-gps menu-icon"></i></span>
                  <span class="menu-title">Catalog List</span>
                  {/* <i class="menu-arrow"></i> */}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="add-new-catalog">
                  <span class="icon-bg"><i class="mdi mdi-contacts menu-icon"></i></span>
                  <span class="menu-title">Add New Catalog</span>
                </a>
              </li> 
              <li class="nav-item">
                <a class="nav-link" href="web-orders">
                  <span class="icon-bg"><i class="mdi mdi-contacts menu-icon"></i></span>
                  <span class="menu-title">WEB Orders</span>
                </a>
              </li> 
              
              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-settings menu-icon"></i>
                    <span class="menu-title">Settings</span>
                  </a>
                </div>
              </li>

              <li class="nav-item sidebar-user-actions">
                <div class="sidebar-user-menu">
                  <a href="#" class="nav-link"><i class="mdi mdi-logout menu-icon"></i>
                    <span class="menu-title">Log Out</span></a>
                </div>
              </li>
            </ul>
          </nav>

          <div class="main-panel">
          <div class="content-wrapper">
            <div class="page-header">
              <h3 class="page-title">Add New Catalog</h3>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/catalog-list" class="btn btn-primary">Back</a></li>
                  {/* <li class="breadcrumb-item active" aria-current="page">Catalog Price Update</li> */}
                </ol>
              </nav>
            </div>
              <div class="col-12 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                  
    <div className="productDetails">
    <form class="forms-sample">
        <div class="row">
            <div class="col-6 grid-margin">
            <div class="form-group">
        <label for="store">Department</label>
        <select class="form-control" id="store" onChange={selectDept}>
        <option value="">Select Department</option>
        <option value="001">ARTS & CRAFTS</option>
        <option value="002">BOOKS</option>
        <option value="004">ACTIVE PLAY & SKILL TOYS</option>
        <option value="006">GAMES & PUZZLES</option>
        <option value="007">DOLLS</option>
        <option value="009">CONSTRUCTION & BUILDING</option>
        <option value="011">IMPULSE</option>
        <option value="012">PLUSH</option>
        <option value="013">IMAGINATIVE & PRETEND PLAY</option>
        <option value="014">SCIENCE</option>
        <option value="015">TRANSIT</option>
        <option value="017">TRADING CARDS</option>
        <option value="018">FASHION</option>
        <option value="019">DEVELOPMENTAL & EDUCATIONAL</option>
        <option value="021">FOOD</option>
        <option value="029">SERVICES</option>
        <option value="030">SUPPLIES</option>
        <option value="999">NEON ELEPHANT</option>

        </select>

      </div>
      <div class="form-group">
        <label for="primary_vendor">Primary Vendor</label>
        <input type="text" class="form-control" id="primary_vendor" placeholder="" onChange={(e)=>setPrimaryVendor(e.target.value)}/>
      </div> 
      {/* <div class="form-group">
        <label for="upc">UPC</label>
        <input type="text" class="form-control" id="upc" placeholder="" onChange={(e)=>setUPC(e.target.value)}/>
      </div>  */}
      <div class="form-group">
        <label for="taxgroupcode">TaxGroupCode</label>
        <input type="text" class="form-control" id="taxgroupcode" placeholder="" onChange={(e)=>setTaxGroupCode(e.target.value)}/>
      </div>             
            </div>
            <div class="col-6 grid-margin">
            <div class="form-group">
        <label for="dept_class_code">Department Class Code</label>
         <select class="form-control" id="store" onChange={selectDeptClassCode}>
        <option value="">Select Department</option>
        <option value="001">ACTIVITY KITS</option>
        <option value="002">ART SUPPLIES</option>
        <option value="003">FASHION & JEWELRY KITS</option>
        <option value="021">ACTIVITY BOOKS</option>
        <option value="022">BOARD & CLOTH BOOKS</option>
        <option value="023">CHAPTER & SERIES BOOKS</option>
        <option value="024">LEARN TO READ BOOKS</option>
        <option value="025">WORKBOOKS</option>
        <option value="026">HARDCOVER</option>
        <option value="027">PAPERBACK</option>
        <option value="028">BABY & INDESTRUCTIBLES</option>
        <option value="041">BUBBLES & CHALK</option>
        <option value="042">INDOOR PLAY</option>
        <option value="043">LAWN & GARDEN</option>
        <option value="044">OUTDOOR PLAY</option>
        <option value="045">POOL, WATER & SAND</option>
        <option value="046">RIDE-ONS</option>
        <option value="047">SKILL TOYS</option>
        <option value="048">SPORTS</option>
        <option value="061">BRAINTEASERS</option>
        <option value="062">CARD GAMES</option>
        <option value="063">ELECTRONIC </option>
        <option value="064">FAMILY GAMES </option>
        <option value="065">PUZZLES </option>
        <option value="066">TABLETOP GAMES </option>
        <option value="067">HANDHELD GAMES </option>
        <option value="261">LEARNING AIDS </option>
        <option value="081">BABY DOLLS & ACCESSORIES</option>
        <option value="082">COLLECTIBLES</option>
        <option value="083">FASHION DOLLS & ACCESSORIES</option>
        <option value="084">DOLL HOUSES, PLAYSETS & ACCESSORIES</option>
        <option value="101">INTERLOCKING</option>
        <option value="102">MAGNETIC</option>
        <option value="103">MARBLE RUNS</option>
        <option value="121">BIN ITEMS</option>
        <option value="082">COLLECTIBLES</option>
        <option value="122">FIDGETS</option>
        <option value="123">GREETING CARDS</option>
        <option value="124">HEALTH & SAFETY</option>
        <option value="125">STICKERS</option>
        <option value="082">COLLECTIBLES</option>
        </select>

      </div>
      <div class="form-group">
        <label for="description">Product Description</label>
        <input type="text" class="form-control" id="description" onChange={(e)=>setDescription(e.target.value)}/>
      </div>
      <div class="form-group">
        <label for="clu">CLU</label>
        <input type="text" class="form-control" id="clu" onChange={(e)=>setClu(e.target.value)}/>
      </div>
      <div class="form-group">
        <label for="baseprice">Base Price</label>
        <input type="text" class="form-control" id="baseprice" placeholder="" onChange={(e)=>setBasePrice(e.target.value)}/>
      </div>       
            </div>
        </div>

      <button type="submit" class="btn btn-primary mr-2" onClick={newCatalog}>Add</button>
      {/* <button class="btn btn-light">Cancel</button> */}
    </form>
    </div>
 
                  </div>
                </div>
              </div>
            </div>
          </div> 
    </div>  
    <Footer />

</>
}
