export class API{
//   static getData(){
//     fetch('https://jsonplaceholder.typicode.com/photos')
//     .then((res) => {
//         return res.json();
//     })
//     .then((data) => {
//         return data
//     });
// }

static addCatalog(props){


  const data = new URLSearchParams({
    dept: props.dept,
    deptCode: props.deptCode,
    primaryVendor: props.primaryVendor,
    description: props.description,
    clu: props.clu,
    upc: props.upc,
    taxGroupCode: props.taxGroupCode,
    basePrice: props.basePrice
  });
  
  fetch('https://dealer.tvsgreen.com/tw/addCatalog.php', {
    method: 'POST',
    body: data
  })
    .then(response => response.json())
    .catch(error => console.error('Error:', error));

  // return fetch ('https://dealer.tvsgreen.com/tw/addCatalog.php', {
  //   method: 'POST', // or 'PUT', depending on the API
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify(data) // Convert data to JSON string
  // })
  // .then(response => response.json()) // Parse JSON response
  // .then(result => result(result))

}

static getData(page) {
  let url = ''
  if(page.page === 0){
    url = 'https://dealer.tvsgreen.com/tw/catalog.php'
  }else{
    url = 'https://dealer.tvsgreen.com/tw/catalog.php?page='+page.page
  }
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((resp)=> resp.json());
}

static getSearchData(props) {
  return fetch(`https://dealer.tvsgreen.com/tw/getSearchData.php?searchKey=${props.searchKey}`)
  .then((response) => response.json())
}

static getSpecificData(props){
  return fetch(`https://dealer.tvsgreen.com/tw/editCatalog.php?id=${props.id}`)
  .then((response) => response.json())
}  

static updateCatalogPrice(props){
  // var myHeaders = new Headers();
  // myHeaders.append("Content-type", "application/json");
  
  // var raw = JSON.stringify({
  //   "id": props.id,
  //   "title": props.title,
  //   "itemnumber": props.itemnumber,
  //   "price": props.newPrice,
  // });
  
  // var requestOptions = {
  //   method: 'PUT',
  //   headers: myHeaders,
  //   body: raw,
  //   redirect: 'follow'
  // };
  
  return fetch(`https://dealer.tvsgreen.com/tw/priceUpdate.php?store=${props.store}&id=${props.itemnumber}&newprice=${props.newPrice}`)
    .then(response => response.json())
}


}


